import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
*::-webkit-scrollbar {
  background-color: #fff;
  width: 10px;
  height: 10px;
}

*::-webkit-scrollbar-track {
  background-color: #fff;
}

*::-webkit-scrollbar-track:hover {
  background-color: #f4f4f4;
}

*::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius: 16px;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: #a0a0a5;
}

*::-webkit-scrollbar-button {
  display: none;
  padding:0;
  margin: 0;
}
`;
