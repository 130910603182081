import { lazy } from 'react';
import { BrowserRouter, Route, Routes as Switch } from 'react-router-dom';

import { ThemeProvider } from '@mui/material';

import { QueryClient, QueryClientProvider } from 'react-query';

import { ToastContainer } from 'react-toastify';

import ScrollTop from '../components/ScrollTop';
import PageLoadable from '../components/PageLoadable';

import { AuthProvider } from '../context/AuthContext';

import { useThemeContext } from '../theme/ThemeContext';

import Private from '../pages/Private';

import Interceptor from '../services/api/interceptor';
import { DrawerProvider } from '../context/DrawerContext';
import { Analytics } from './Analycs';

const Home = PageLoadable(lazy(() => import('../pages/Home')));
const Login = PageLoadable(lazy(() => import('../pages/Login')));
const MainLayout = PageLoadable(lazy(() => import('../layout/MainLayout')));
const LogsErrors = PageLoadable(lazy(() => import('../pages/LogsErrors')));
const ModulosWeb = PageLoadable(lazy(() => import('../pages/ModulosWeb')));
const ModulosWebClient = PageLoadable(lazy(() => import('../pages/ModulosWebClient')));

export default function Routes() {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        staleTime: 1000 * 60 * 30, // cache 30 segundos,
        cacheTime: 1000 * 60 * 30,
      },
    },
  });
  const { selectedTheme, theme } = useThemeContext();
  return (
    <ThemeProvider theme={selectedTheme}>
      <BrowserRouter basename={process.env.REACT_APP_BASE_ROUTE}>
        <ScrollTop>
          <QueryClientProvider client={queryClient}>
            <AuthProvider>
              <DrawerProvider>
                <Interceptor>
                  <Switch>
                    <Route element={<Analytics />}>
                      <Route path="/login" element={<Login />} />
                      <Route path="" element={<Private />}>
                        <Route path="" element={<MainLayout />}>
                          <Route path="" element={<Home />} />
                          <Route path="logs-errors" element={<LogsErrors />} />
                          <Route path="modulos-web" element={<ModulosWeb />} />
                          <Route path="modulos-web-client" element={<ModulosWebClient />} />
                        </Route>
                      </Route>
                    </Route>
                  </Switch>
                </Interceptor>
              </DrawerProvider>
            </AuthProvider>
          </QueryClientProvider>
        </ScrollTop>
      </BrowserRouter>
      <ToastContainer theme={theme} />
    </ThemeProvider>
  );
}
