import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getPerformance } from 'firebase/performance';

const config = {
  apiKey: 'AIzaSyBNdIv5h6bTds8xjeJVQRLb-LvjtJgkZXQ',
  authDomain: 'bitlab-configs.firebaseapp.com',
  projectId: 'bitlab-configs',
  storageBucket: 'bitlab-configs.appspot.com',
  messagingSenderId: '350039087544',
  appId: '1:350039087544:web:1fff884cff657feb56974d',
  measurementId: 'G-D5R585FZ7T',
};

const firebaseApp = initializeApp(config);

const analytics = getAnalytics(firebaseApp);

const performance = getPerformance(firebaseApp);

export const firebaseService = {
  firebaseApp,
  analytics,
  performance,
};
