import {
  createContext, useContext, useEffect, useMemo, useState,
} from 'react';

import { useMediaQuery, useTheme } from '@mui/material';

interface DrawerContextProps {
  children: React.ReactNode;
}

interface DrawerContextData {
  openDrawer: boolean
  setOpenDrawer: React.Dispatch<React.SetStateAction<boolean>>
  handleDrawerItemClick: () => void
  handleDrawerToggle: () => void
}

const DrawerContext = createContext<DrawerContextData | null>(null);
DrawerContext.displayName = 'Drawer Context';
export default DrawerContext;

export function DrawerProvider({ children }: DrawerContextProps) {
  const theme = useTheme();
  const matchDownLG = useMediaQuery(theme.breakpoints.down('xl'));

  // drawer toggler
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);

  useEffect(() => {
    setOpenDrawer(!matchDownLG);
  }, [matchDownLG, setOpenDrawer]);

  const values = useMemo(
    () => ({
      openDrawer,
      setOpenDrawer,
      handleDrawerItemClick: () => {
        setOpenDrawer(!matchDownLG);
      },
      handleDrawerToggle: () => {
        setOpenDrawer(!openDrawer);
      },
    }),
    [matchDownLG, openDrawer],
  );

  return <DrawerContext.Provider value={values}>{children}</DrawerContext.Provider>;
}

export function useDrawerContext() {
  const context = useContext(DrawerContext);
  if (!context) {
    throw new Error(
      'useDrawerContext must be used withing a DrawerContext.Provider',
    );
  }

  return context;
}
