import { ThemeOptions } from '@mui/material/styles';

export const themeOptionsDark: ThemeOptions = {
  palette: {
    mode: 'dark',
    primary: {
      main: '#9396f9',
    },
    secondary: {
      main: '#f50057',
    },
    background: {
      default: '#44475a',
      paper: '#282a36',
    },
    success: {
      main: '#50fa7b',
    },
    warning: {
      main: '#ffb86c',
    },
    error: {
      main: '#ff5555',
    },
    info: {
      main: '#bd93f9',
    },
  },
  typography: {
    fontFamily: 'Source Sans Pro',
  },
  spacing: 8,
  shape: {
    borderRadius: 8,
  },
};

export const themeOptionsLight: ThemeOptions = {
  palette: {
    mode: 'light',
    primary: {
      main: '#02374c',
    },
    secondary: {
      main: 'rgb(81, 81, 81)',
    },
    background: {
      paper: '#f5f5f5',
    },
  },
  typography: {
    fontFamily: 'Source Sans Pro',
  },
  spacing: 8,
  shape: {
    borderRadius: 8,
  },
};
