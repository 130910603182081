import { Navigate, Outlet } from 'react-router-dom';
import { useAuthContext } from '../../context/AuthContext';

export default function Private() {
  const { isAuthenticated, user, isLoading } = useAuthContext();

  if (!isAuthenticated && !isLoading) {
    return <Navigate to="/login" />;
  }

  if (!user) {
    return null;
  }

  return <Outlet />;
}
