import LinearProgress from '@mui/material/LinearProgress';
import { LoaderWrapper } from './styles';

function LinearLoader() {
  return (
    <LoaderWrapper>
      <LinearProgress color="primary" />
    </LoaderWrapper>
  );
}

export default LinearLoader;
