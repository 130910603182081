import { Suspense } from 'react';
import LinearLoader from './LinearLoader';

const PageLoadable = (Component:
// eslint-disable-next-line func-names, @typescript-eslint/no-explicit-any
React.LazyExoticComponent<() => JSX.Element>) => function (props: any) {
  return (
    <Suspense fallback={<LinearLoader />}>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <Component {...props} />
    </Suspense>
  );
};

export default PageLoadable;
