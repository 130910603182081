import {
  createContext, useCallback, useContext, useEffect, useMemo, useState,
} from 'react';

import { ptBR } from '@mui/x-data-grid';
import { createTheme, Theme } from '@mui/material';

import { themeOptionsDark, themeOptionsLight } from './theme';

interface ThemeContextData {
  theme: 'light' | 'dark';
  themeToggler: () => void;
  selectedTheme: Theme;
}

interface ThemeContextProps {
  children: React.ReactNode;
}

const ThemeContext = createContext<ThemeContextData | null>(null);
ThemeContext.displayName = 'Theme Context';
export default ThemeContext;

export function ThemeContextProvider({ children }: ThemeContextProps) {
  const [theme, setTheme] = useState<'light' | 'dark'>('light');

  useEffect(() => {
    const themeSaved = localStorage.getItem('theme-application');

    if (themeSaved != null) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      setTheme(themeSaved);
    }
  }, []);

  const selectedTheme = useMemo(
    () => (
      createTheme((theme === 'light'
        ? themeOptionsLight
        : themeOptionsDark), ptBR)
    ),
    [theme],
  );

  const themeToggler = useCallback(() => {
    const newTheme = theme === 'light' ? 'dark' : 'light';
    localStorage.setItem('theme-application', newTheme);
    setTheme(newTheme);
  }, [theme]);

  const values = useMemo(() => ({
    theme,
    themeToggler,
    selectedTheme,
  }), [theme, themeToggler, selectedTheme]);

  return (
    <ThemeContext.Provider value={values}>
      {children}
    </ThemeContext.Provider>
  );
}

export function useThemeContext() {
  const context = useContext(ThemeContext);
  if (!context) {
    throw new Error('useThemeContext must be used within a ThemeContextProvider');
  }
  return context;
}
