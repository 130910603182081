import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

interface ScrollTopProps {
  children: JSX.Element;
}

export default function ScrollTop({ children }: ScrollTopProps) {
  const location = useLocation();
  const { pathname } = location;

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, [pathname]);

  return children;
}
