import { RefreshToken } from '../types/RefreshToken';
import { User } from '../types/user';
import apiAnonymous from './api/apiAnonymous';

export interface LoginProps {
  userName: string,
  password: string,
}

export function Login(body: LoginProps) {
  return apiAnonymous.post<User>(
    'v1/login/enterpriseconfig',
    body,
    {
      headers: {
        codBanco: process.env.REACT_APP_DATABASE,
      },
    },
  );
}

export async function RevalidateToken(token: string) {
  const result = await apiAnonymous.post<RefreshToken>('v1/login/refresh_valid_token', null, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return result;
}
