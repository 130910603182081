import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AxiosError } from 'axios';
import api from '.';
import { useAuthContext } from '../../context/AuthContext';

interface InterceptorProps {
  children: JSX.Element;
}

export default function Interceptor({ children }: InterceptorProps) {
  const {
    user, isAuthenticated, RevalidateTokenUser, LogOut,
  } = useAuthContext();
  const navigate = useNavigate();

  useEffect(() => {
    api.interceptors.request.use(async (config) => {
      if (isAuthenticated) {
        const configToken = config;
        const token = user?.userToken.token ?? '';
        const expires = user?.userToken.expires ?? '';
        const createTime = user?.userToken.createTime ?? '';

        if (expires && createTime) {
          const dateNow = new Date();
          // revalidate a cada uma hora
          const dateToValidate = new Date(
            new Date(expires).getTime() - 1 * 60 * 60 * 1000,
          );

          if (dateNow > dateToValidate) {
            const newToken = await RevalidateTokenUser();
            configToken.headers.Authorization = `Bearer ${newToken}`;
            // eslint-disable-next-line no-console
            console.log('atualizando token');
            return configToken;
          }
        }

        if (token) {
          configToken.headers.Authorization = `Bearer ${token}`;
        }

        return configToken;
      }
      return config;
    });

    api.interceptors.response.use(
      (response) => response,
      (error: AxiosError) => {
        if (error?.response?.status === 401) {
          LogOut();
        }

        return Promise.reject(error);
      },
    );

    return () => {
      api.interceptors.request.clear();
      api.interceptors.response.clear();
    };
  }, [RevalidateTokenUser, isAuthenticated, user, LogOut, navigate]);

  return children;
}
