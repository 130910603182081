import { styled } from '@mui/material';

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
export const LoaderWrapper = styled('div')(({ theme }) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  zIndex: 2001,
  width: '100%',
  '& > * + *': {
    marginTop: theme.spacing(2),
  },
}));
