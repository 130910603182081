import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import { AxiosError } from 'axios';
import { Login, LoginProps, RevalidateToken } from '../services/LoginService';
import { User } from '../types/user';
import api from '../services/api';
import { localStorageKeys } from '../configs/localStorageKeys';

interface AuthContextProps {
  children: React.ReactNode;
}

interface AuthContextData {
  isLoading: boolean;
  isAuthenticated: boolean;
  user: User | null;
  LoginIn: (body: LoginProps) => Promise<void>;
  RevalidateTokenUser: () => Promise<string>;
  LogOut: () => void;
}

const AuthContext = createContext<AuthContextData | null>(null);
AuthContext.displayName = 'Auth Context';
export default AuthContext;

export function AuthProvider({ children }: AuthContextProps) {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [user, setUser] = useState<User | null>(null);

  useEffect(() => {
    try {
      setIsLoading(true);
      const userLocal = localStorage.getItem(localStorageKeys.userSettings);

      if (userLocal == null) {
        setIsAuthenticated(false);
        setUser(null);
      } else {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const userJson: User = JSON.parse(userLocal);
        setIsAuthenticated(true);
        setUser(userJson);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const LoginIn = useCallback(async (body: LoginProps) => {
    try {
      const response = await Login(body);

      if (response.status === 200 && response.data.userToken.role.find((x) => x === 'FWEB_CONFIG')) {
        await queryClient.invalidateQueries();

        const dateCreateServer = new Date(response.data.userToken.createTime);
        const dateExpireServer = new Date(response.data.userToken.expires);

        const diffDate = dateExpireServer.getTime() - dateCreateServer.getTime();

        const dateNow = new Date();
        const dateExpire = new Date(new Date().getTime() + diffDate);

        response.data.userToken.createTime = dateNow;
        response.data.userToken.expires = dateExpire;

        setUser(response.data);
        localStorage.setItem(localStorageKeys.userSettings, JSON.stringify(response.data));
        setIsAuthenticated(true);
        api.defaults.headers.Authorization = `Bearer ${response.data.userToken.token}`;
        navigate('/');
      } else {
        setIsAuthenticated(false);
        setUser(null);
        localStorage.removeItem(localStorageKeys.userSettings);
        throw new AxiosError('Usuário não possui permissão para acessar a aplicação!', 'custom');
      }
    } catch (error) {
      setIsAuthenticated(false);
      setUser(null);
      localStorage.removeItem(localStorageKeys.userSettings);
      throw error;
    }
  }, [navigate, queryClient]);

  const RevalidateTokenUser = useCallback(async () => {
    try {
      if (user?.userToken.token) {
        const response = await RevalidateToken(user?.userToken.token);
        if (response.status === 200) {
          setIsAuthenticated(true);

          const dateCreateServer = new Date(response.data.value.createTime);
          const dateExpireServer = new Date(response.data.value.expires);

          const diffDate = dateExpireServer.getTime() - dateCreateServer.getTime();

          const dateNow = new Date();
          const dateExpire = new Date(new Date().getTime() + diffDate);

          const newUser = {
            regras: [...user.regras],
            userToken: {
              ...user.userToken,
              createTime: dateNow,
              expires: dateExpire,
              token: response.data.value.token,
            },

          };

          setUser(newUser);
          localStorage.setItem(localStorageKeys.userSettings, JSON.stringify(newUser));

          return newUser.userToken.token;
        }
        setIsAuthenticated(false);
        setUser(null);
        localStorage.removeItem(localStorageKeys.userSettings);
      } else {
        setIsAuthenticated(false);
        setUser(null);
        localStorage.removeItem(localStorageKeys.userSettings);
      }
    } catch (error) {
      setIsAuthenticated(false);
      setUser(null);
      localStorage.removeItem(localStorageKeys.userSettings);
      throw error;
    }

    return '';
  }, [user]);

  const LogOut = useCallback(() => {
    setIsAuthenticated(false);
    setUser(null);
    localStorage.removeItem(localStorageKeys.userSettings);
  }, []);

  const values = useMemo(
    () => ({
      isLoading,
      isAuthenticated,
      user,
      LoginIn,
      RevalidateTokenUser,
      LogOut,
    }),
    [isLoading, isAuthenticated, user, LoginIn, RevalidateTokenUser, LogOut],
  );

  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>;
}

export function useAuthContext() {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error(
      'useAuthContext must be used withing a AuthContext.Provider',
    );
  }

  return context;
}
