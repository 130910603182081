import React from 'react';
import ReactDOM from 'react-dom/client';
import { CssBaseline } from '@mui/material';

import GlobalStyles from './theme/global';

import { ThemeContextProvider } from './theme/ThemeContext';

import Routes from './Routes';
// import reportWebVitals from './reportWebVitals';

// eslint-disable-next-line import/no-extraneous-dependencies
import '@fontsource/source-sans-pro';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import 'react-toastify/dist/ReactToastify.min.css';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <React.StrictMode>
    <CssBaseline />
    <GlobalStyles />
    <ThemeContextProvider>
      <Routes />
    </ThemeContextProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
